import { createSelector, Selector } from 'reselect'
import { ConsumerName } from '../../../../../domain/consumer/consumer-profile/consumer-name.interface'
import { RootStore } from '../../../../../store/root.store'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { ConsumerProfileState } from '../types/consumer-profile-state.interface'
import { CreateConsumerProfileForm } from '../types/create-consumer-profile-form.interface'
import { CreateConsumerProfileWithAssetForm } from '../types/create-consumer-profile-with-asset-form.interface'
import { ConsumerProfileActions } from './consumer-profile.actions'
import { PreQualifierAssetTypeUiConfig } from '../../../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type-ui-config/pre-qualifier-asset-type-ui-config.interface'
import { ConsumerProfilePostSubmissionConfig } from '../../../../../domain/consumer/consumer-profile/types/consumer-profile-post-submission-config/consumer-post-submission-config.interface'

const getConsumerProfileState = (rootState: RootState): ConsumerProfileState =>
  rootState.consumerState.consumerProfileState

const getConsumerName: Selector<RootState, ConsumerName> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => ({
    firstName: state.firstName,
    lastName: state.lastName
  })
)

const getConsumerProfileId: Selector<
  RootState,
  ConsumerProfileState['consumerProfileId']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.consumerProfileId
)
const getConsumerFirstName: Selector<
  RootState,
  ConsumerProfileState['firstName']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.firstName
)
const getConsumerLastName: Selector<
  RootState,
  ConsumerProfileState['lastName']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.lastName
)

const getConsumerPhoneNumber: Selector<
  RootState,
  ConsumerProfileState['phoneNumber']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.phoneNumber
)

const getConsumerAssetSubtype: Selector<
  RootState,
  ConsumerProfileState['assetSubtypeId']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.assetSubtypeId
)

const getConsumerAssetType: Selector<
  RootState,
  ConsumerProfileState['assetTypeId']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.assetTypeId
)

const getConsumerBannerUrl: Selector<
  RootState,
  ConsumerProfileState['bannerUrl']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.bannerUrl
)

const getConsumerProfileAssetTypeImageUrl: Selector<
  RootState,
  PreQualifierAssetTypeUiConfig['assetTypeImageUrl']> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.preQualifierAssetTypeUiConfig.assetTypeImageUrl || ''
);

const getConsumerProfileAssetTypeImageTitle: Selector<
  RootState,
  PreQualifierAssetTypeUiConfig['assetTypeImageTitle']> | null = createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.preQualifierAssetTypeUiConfig.assetTypeImageTitle || null
);

const getConsumerDob: Selector<RootState, ConsumerProfileState['dateOfBirth']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.dateOfBirth
  )
const getConsumerEmail: Selector<RootState, ConsumerProfileState['email']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.email
  )
const getConsumerNetMonthlyIncome: Selector<
  RootState,
  ConsumerProfileState['netIncome']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.netIncome
)

const getPreApprovalStatus: Selector<
  RootState,
  ConsumerProfileState['preApprovalStatus']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.preApprovalStatus
)
const getPreApprovalAmount: Selector<
  RootState,
  ConsumerProfileState['preApprovalAmount']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.preApprovalAmount
)
const getReferenceCode: Selector<
  RootState,
  ConsumerProfileState['referenceCode']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.referenceCode
)

const getConsumerAddress: Selector<RootState, ConsumerProfileState['address']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.address
  )

const getElectronicCommunicationConsent: Selector<
  RootState,
  ConsumerProfileState['authorizedElectronicCommunication']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) =>
    state.authorizedElectronicCommunication ?? false
)

const getIsPreApprovalApplicationReassigned: Selector<
  RootState,
  ConsumerProfileState['isPreApprovalApplicationReassigned']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.isPreApprovalApplicationReassigned
)

const getCanViewOfferPage: Selector<
  RootState,
  ConsumerProfileState['canViewOfferPage']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.canViewOfferPage
)

const getConsumerProfileAsset: Selector<
  RootState,
  ConsumerProfileState['consumerProfileAsset']
> = createSelector(
  getConsumerProfileState,
  (state: ConsumerProfileState) => state.consumerProfileAsset
)

const getVendorId: Selector<RootState, ConsumerProfileState['vendorId']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.vendorId
  )

const getVendor: Selector<RootState, ConsumerProfileState['vendor']> =
    createSelector(
      getConsumerProfileState,
      (state: ConsumerProfileState) => state.vendor
    )

const createConsumerProfile = (form: CreateConsumerProfileForm) =>
  RootStore.dispatch(ConsumerProfileActions.createConsumerProfile(form))

const createConsumerProfileWithAsset = (
  form: CreateConsumerProfileWithAssetForm
) =>
  RootStore.dispatch(
    ConsumerProfileActions.createConsumerProfileWithAsset(form)
  )

const setConsumerProfileId = (id: number) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerProfileId(id))

const setConsumerName = (name: ConsumerName) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerName(name))

const setConsumerPhoneNumber = (
  phoneNumber: ConsumerProfileState['phoneNumber']
) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerPhoneNumber(phoneNumber))

const setConsumerAssetType = (
  assetTypeId: ConsumerProfileState['assetTypeId']
) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerAssetType(assetTypeId))

const setConsumerAssetSubtype = (
  assetSubtypeId: number | null
) => RootStore.dispatch(ConsumerProfileActions.setConsumerAssetSubtype(assetSubtypeId))

const setConsumerDob = (dateOfBirth: ConsumerProfileState['dateOfBirth']) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerDob(dateOfBirth))

const setBannerUrl = (bannerUrl: ConsumerProfileState['bannerUrl']) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerBannerUrl(bannerUrl))

const setConsumerEmail = (email: ConsumerProfileState['email']) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerEmail(email))

const setConsumerAddress = (
  address: NonNullable<ConsumerProfileState['address']>
) => RootStore.dispatch(ConsumerProfileActions.setConsumerAddress(address))

const setConsumerNetIncome = (netIncome: ConsumerProfileState['netIncome']) =>
  RootStore.dispatch(ConsumerProfileActions.setConsumerNetIncome(netIncome))

const setVendorId = (vendorId: string) =>
  RootStore.dispatch(ConsumerProfileActions.setVendorId(vendorId))

const setAuthorizedElectronicCommunication = (
  authorizedElectronicCommunication: ConsumerProfileState['authorizedElectronicCommunication']
) =>
  RootStore.dispatch(
    ConsumerProfileActions.setAuthorizedElectronicCommunication(
      authorizedElectronicCommunication
    )
  )

const setLoanOfferPageViewed = () =>
  RootStore.dispatch(ConsumerProfileActions.setLoanOfferPageViewed())

const setRefId = (refId: string) => {
  RootStore.dispatch(ConsumerProfileActions.setRefId(refId))
}

const getRefId: Selector<RootState, ConsumerProfileState['refId']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.refId
  )

const setReferrer = (referrer: string | null) => {
  RootStore.dispatch(ConsumerProfileActions.setReferrer(referrer))
}

const getReferrer: Selector<RootState, ConsumerProfileState['referrer']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.referrer
  )

const setEventId = (eventId: string | null) => {
  RootStore.dispatch(ConsumerProfileActions.setEventId(eventId))
}

const getEventId: Selector<RootState, ConsumerProfileState['eventId']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.eventId
  )

const setCampaignId = (campaignId: string | null) => {
  RootStore.dispatch(ConsumerProfileActions.setCampaignId(campaignId))
}

const getCampaignId: Selector<RootState, ConsumerProfileState['campaignId']> =
  createSelector(
    getConsumerProfileState,
    (state: ConsumerProfileState) => state.campaignId
  )

const getPostSubmissionConfig: Selector<RootState, ConsumerProfilePostSubmissionConfig> =
    createSelector(
      getConsumerProfileState,
      (state: ConsumerProfileState) => state.postSubmissionConfig
    )

  const setPreApprovalStatus = (preApprovalStatus: ConsumerProfileState['preApprovalStatus']) => {
    RootStore.dispatch(ConsumerProfileActions.setPreApprovalStatus(preApprovalStatus))
  }

  const fetchVendorDetails = (vendorId: string) => {
    RootStore.dispatch(ConsumerProfileActions.fetchVendorDetails(vendorId))
  }

export const ConsumerProfileStore = {
  getConsumerProfileState,
  createConsumerProfile,
  createConsumerProfileWithAsset,
  getConsumerProfileId,
  getConsumerAddress,
  getConsumerDob,
  getConsumerEmail,
  getConsumerFirstName,
  getConsumerLastName,
  getConsumerName,
  getConsumerPhoneNumber,
  getConsumerAssetSubtype,
  getConsumerAssetType,
  getConsumerBannerUrl,
  getConsumerNetMonthlyIncome,
  getPreApprovalStatus,
  getPreApprovalAmount,
  getReferenceCode,
  getIsPreApprovalApplicationReassigned,
  setVendorId,
  setConsumerProfileId,
  setConsumerAddress,
  setConsumerDob,
  setConsumerEmail,
  setConsumerName,
  setConsumerPhoneNumber,
  setConsumerAssetType,
  setConsumerAssetSubtype,
  setConsumerNetIncome,
  getElectronicCommunicationConsent,
  setAuthorizedElectronicCommunication,
  getCanViewOfferPage,
  setLoanOfferPageViewed,
  getConsumerProfileAsset,
  setBannerUrl,
  getVendorId,
  getVendor,
  setRefId,
  getRefId,
  setReferrer,
  getReferrer,
  setEventId,
  getEventId,
  setCampaignId,
  getCampaignId,
  getConsumerProfileAssetTypeImageUrl,
  getConsumerProfileAssetTypeImageTitle,
  getPostSubmissionConfig,
  setPreApprovalStatus,
  fetchVendorDetails,
}
