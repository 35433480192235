import { withShowIf } from '../../../../../ui-library/helpers/with-show-if/with-show-if.component';
import { AssetTypeDetailsProps } from './asset-type-details.props';
import { AssetTypeDetailsStyles } from './asset-type-details.styles';
import { Paragraph } from '../../../../../ui-library/components/paragraph/paragraph.component';
import { ShowIfFragment } from '../../../../../ui-library/components/show-if/show-if-fragment.component';
import { useMemo } from 'react';

export const AssetTypeDetails = withShowIf((props: AssetTypeDetailsProps) => {
    const vendorLogo = useMemo(() => {
        if (props.vendor && props.vendor.logoUrl) {
            return props.vendor.logoUrl;
        }
        return '';
    }, [props.vendor]);

    return (
        <div className={AssetTypeDetailsStyles.assetImageContainer}>
            <div className={AssetTypeDetailsStyles.assetDetailsHeader}>
                <ShowIfFragment showIf={vendorLogo !== ''}>
                    <span className={AssetTypeDetailsStyles.vendorLogoContainer}>
                        <img className={AssetTypeDetailsStyles.vendorLogo} src={vendorLogo} alt="Vendor Logo" />
                    </span>
                </ShowIfFragment>
            <Paragraph className={AssetTypeDetailsStyles.assetName}>
                {props.assetTypeName}
            </Paragraph>
            </div>
            <div className={AssetTypeDetailsStyles.imageContainer}>
                <img
                    className={AssetTypeDetailsStyles.assetImage}
                    src={props.assetTypeImageUrl ?? ''}
                    {...props.assetTypeImageUat}
                />
            </div>
        </div>
    )
});