import { Reducer } from 'redux'
import { createConsumerProfileState } from '../types/consumer-profile-state.functions'
import { ConsumerProfileState } from '../types/consumer-profile-state.interface'
import {
  ConsumerProfileAction,
  ConsumerProfileActionType
} from './consumer-profile.actions'

export const consumerProfileReducer: Reducer<
  ConsumerProfileState,
  ConsumerProfileActionType
> = (
  state: ConsumerProfileState = createConsumerProfileState(),
  action: ConsumerProfileActionType
): ConsumerProfileState => {
  switch (action.type) {
    case ConsumerProfileAction.SetConsumerName:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName
      }
    case ConsumerProfileAction.SetConsumerPhoneNumber:
      return {
        ...state,
        phoneNumber: action.payload
      }
    case ConsumerProfileAction.SetConsumerAssetType:
      return {
        ...state,
        assetTypeId: action.payload
      }
    case ConsumerProfileAction.SetConsumerAssetSubtype:
      return {
        ...state,
        assetSubtypeId: action.payload
      }
    case ConsumerProfileAction.SetConsumerDob:
      return {
        ...state,
        dateOfBirth: action.payload
      }
    case ConsumerProfileAction.SetConsumerBannerUrl:
      return {
        ...state,
        bannerUrl: action.payload
      }
    case ConsumerProfileAction.SetConsumerEmail:
      return {
        ...state,
        email: action.payload
      }
    case ConsumerProfileAction.SetConsumerAddress:
      return {
        ...state,
        address: action.payload
      }
    case ConsumerProfileAction.SetConsumerNetIncome:
      return {
        ...state,
        netIncome: action.payload
      }
    case ConsumerProfileAction.SetElectonicCommunicationConsent:
      return {
        ...state,
        authorizedElectronicCommunication: action.payload
      }
    case ConsumerProfileAction.SetConsumerProfileId:
      return {
        ...state,
        consumerProfileId: action.payload
      }
    case ConsumerProfileAction.SetVendorId:
      return {
        ...state,
        vendorId: action.payload
      }
    case ConsumerProfileAction.SetConsumerProfile:
      return {
        ...state,
        ...action.payload
      }
    case ConsumerProfileAction.CreateConsumerProfileSuccess:
      return {
        ...state,
        consumerProfileId: action.payload.consumerProfileId
      }
    case ConsumerProfileAction.SavePreApprovalResult:
      return {
        ...state,
        preApprovalStatus: action.payload.preApprovalStatus,
        preApprovalAmount: action.payload.preApprovedAmount,
        referenceCode: action.payload.referenceCode
      }
    case ConsumerProfileAction.SetLoanOfferPageViewed:
      return {
        ...state,
        canViewOfferPage: false
      }
    case ConsumerProfileAction.SetRefId:
      return {
        ...state,
        refId: action.payload
      }
    case ConsumerProfileAction.SetReferrer:
      return {
        ...state,
        referrer: action.payload
      }
    case ConsumerProfileAction.SetEventId:
      return {
        ...state,
        eventId: action.payload
      }
    case ConsumerProfileAction.SetCampaignId:
      return {
        ...state,
        campaignId: action.payload
      }
    case ConsumerProfileAction.SetPreApprovalStatus:
      return {
        ...state,
        preApprovalStatus: action.payload,
      }
    case ConsumerProfileAction.FetchVendorDetailsSuccess:
      return {
        ...state,
        vendor: action.payload,
      }
    default:
      return state
  }
}
