import { ConsumerPostSubmissionFailedViewStyles } from './consumer-post-submission-failed-view.styles'
import { ConsumerPostSubmissionFailedViewMessages } from './consumer-post-submission-failed-view.messages'
import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { MainLayout } from '../../../../../../../../shared/components/main-layout/main-layout.component'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { useSelector } from 'react-redux'
import { ConsumerPostSubmissionFailedViewUat } from './consumer-post-submission-failed-view.uat'
import { useEffect  } from 'react'
import { PreApprovalStatus } from '../../../../../../../../domain/consumer/consumer-profile/types/pre-approval-status.enum'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { useHistory } from 'react-router'
import { ConsumerRoute } from '../../../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { addVendorIdToPath } from '../../../../../../../../shared/helpers/path-helper'

export const ConsumerPostSubmissionFailedView = () => {
  const history = useHistory()
  const preApprovalStatus: ConsumerProfileState['preApprovalStatus'] =
    useSelector(ConsumerProfileStore.getPreApprovalStatus)
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)

  useEffect(() => {
    if (preApprovalStatus === PreApprovalStatus.Approved) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitApproved))
    } else if (preApprovalStatus === PreApprovalStatus.Declined) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitNoOfferAvailable))
    }
  }, [preApprovalStatus, history])

  return (
    <MainLayout centerAlignDisclaimer>
      <div className={ConsumerPostSubmissionFailedViewStyles.container}>
        <Paragraph
          uat={uatId(ConsumerPostSubmissionFailedViewUat.paragraph)}
          className={ConsumerPostSubmissionFailedViewStyles.paragraph}
        >
          <FormattedMessage {...ConsumerPostSubmissionFailedViewMessages.failedMessage } />
        </Paragraph>
      </div>
    </MainLayout>
  )
}
