import { useSelector } from 'react-redux'
import { MainLayout } from '../../../../shared/components/main-layout/main-layout.component'
import { withShowIf } from '../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { PreQualifierAssetTypeSelectionStore } from './store/pre-qualifier-asset-type-selection.store'
import { SpinnerIcon } from '../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { PreQualifierAssetTypeSelectionView } from './components/pre-qualifier-asset-type-selection-view/pre-qualifier-asset-type-selection-view.component'
import { PreQualifierAssetTypeSelectionStyles } from './pre-qualifier-asset-type-selection.styles'
import { PreQualifierAssetTypeSelectionErrorView } from './components/pre-qualifier-asset-type-selection-error-view/pre-qualifier-asset-type-selection-error-view.component'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useConsumerSessionStatus } from '../consumer-session/hooks/use-consumer-session-active-status.hook'
import { ConsumerSessionStatus } from '../../../../domain/consumer/consumer-session/consumer-session-active-status.enum'
import { ConsumerRoute } from '../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { Language } from '../../../../shared/types/language.enum'
import { SettingsStore } from '../../../app/modules/settings/store/settings.store'
import { ConsumerProfileStore } from '../consumer-profile/store/consumer-profile.store'

export const PreQualifierAssetTypeSelection = withShowIf(() => {
  const history = useHistory()
  const loading = useSelector(PreQualifierAssetTypeSelectionStore.getLoading)
  const error = useSelector(PreQualifierAssetTypeSelectionStore.getFetchError)
  const locale: Language = useSelector(SettingsStore.getLocaleSetting)
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)
  const query = new URLSearchParams(window.location.search)
  const tags = query.getAll('tag') ?? null

  const assetTypes = useSelector(
    PreQualifierAssetTypeSelectionStore.getAssetTypes
  )

  useEffect(() => {
    PreQualifierAssetTypeSelectionStore.fetchAssetTypes(locale, vendorId, tags)
  }, [locale, vendorId])

  useEffect(() => {
    ConsumerProfileStore.fetchVendorDetails(vendorId);
  }, [vendorId]);

  const showPreQualifierAssetTypeSelectionView = () =>
    !loading && !Boolean(error) && assetTypes.length !== 0

  const consumerSessionStatus = useConsumerSessionStatus()

  const showPreQualifierAssetTypeSelectionErrorView = () =>
    !loading && (Boolean(error) || assetTypes.length === 0)

  useEffect(() => {
    if (consumerSessionStatus === ConsumerSessionStatus.PostSubmission) {
      history.push(ConsumerRoute.RouteResolver)
    }
  }, [consumerSessionStatus])

  return (
    <MainLayout
      showDefaultHeader
      hideNavBar
      hideHeader
      showContactInfoFooter={showPreQualifierAssetTypeSelectionErrorView()}
      centerAlignDisclaimer
    >
      <div className={PreQualifierAssetTypeSelectionStyles.loadingContainer}>
        <SpinnerIcon size={SpinnerIconSize.LARGE} showIf={loading} />
      </div>
      <PreQualifierAssetTypeSelectionView
        showIf={showPreQualifierAssetTypeSelectionView()}
        assetTypes={assetTypes}
      />
      <PreQualifierAssetTypeSelectionErrorView showIf={showPreQualifierAssetTypeSelectionErrorView()} />
    </MainLayout>
  )
})
