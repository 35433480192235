import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { getLoadingTimeoutInMillis } from './consumer-post-submission-loading-view.functions'
import { useSelector } from 'react-redux'
import { PreApprovalStatus } from '../../../../../../../../domain/consumer/consumer-profile/types/pre-approval-status.enum'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { ConsumerRoute } from '../../../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { ConsumerPostSubmissionSpinner } from '../../../../components/consumer-post-submission-spinner'
import { addApprovedVariantToPath, addVendorIdToPath } from '../../../../../../../../shared/helpers/path-helper'
import { getPostSubmissionConfigApprovedOffersVariant } from '../../../consumer-post-submission-approved/types/consumer-post-submission-config.function'

export const PostSubmissionLoadingView = () => {
  const history = useHistory()

  const preApprovalStatus: ConsumerProfileState['preApprovalStatus'] =
    useSelector(ConsumerProfileStore.getPreApprovalStatus)
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)

  const canViewOffers = useSelector(ConsumerProfileStore.getCanViewOfferPage)

  const postSubmissionConfig = useSelector(ConsumerProfileStore.getPostSubmissionConfig)
  const postSubmissionApprovalVariant = getPostSubmissionConfigApprovedOffersVariant(postSubmissionConfig)

  useEffect(() => {
    if (preApprovalStatus === PreApprovalStatus.Approved && !canViewOffers) {
      history.push(
        addApprovedVariantToPath(
          addVendorIdToPath(vendorId, ConsumerRoute.SubmitApproved),
          postSubmissionApprovalVariant
        )
      )
    } else if (
      preApprovalStatus === PreApprovalStatus.Approved &&
      canViewOffers
    ) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitOffer))
    } else if (preApprovalStatus === PreApprovalStatus.Declined) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitNoOfferAvailable))
    }
  }, [preApprovalStatus, history, canViewOffers])

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitError))
    }, getLoadingTimeoutInMillis())
    return () => {
      clearTimeout(timeout)
    }
  }, [history])

  return <ConsumerPostSubmissionSpinner />
}
