import { PreQualifierAssetTypeSelection } from './modules/pre-qualifier-asset-type-selection/pre-qualifier-asset-type-selection.component'
import { ConsumerAuthentication } from './modules/consumer-authentication/consumer-authentication.component'
import { MerchantSelectSuccess } from './modules/merchant-selection/components/merchant-select-success/merchant-select-success.component'
import { MerchantSelection } from './modules/merchant-selection/merchant-selection.component'
import { useSelector } from 'react-redux'
import { ConsumerWorkflowStepsStore } from './modules/consumer-workflow/modules/consumer-workflow-steps/store/consumer-workflow-steps.store'
import { useEffect, useMemo } from 'react'
import {
  createConsumerWorkflowRoutes,
  findActiveWorkflowStep
} from './modules/consumer-workflow/consumer-workflow.functions'
import { DynamicRoute } from '../../libraries/router-library/components/dynamic-route/dynamic-route.component'
import { useConsumerSessionStatus } from './modules/consumer-session/hooks/use-consumer-session-active-status.hook'
import { ConsumerSessionStatus } from '../../domain/consumer/consumer-session/consumer-session-active-status.enum'
import { withShowIf } from '../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerRouteResolver } from './modules/consumer-route-resolver/consumer-route-resolver.component'
import { ConsumerSubmitStepStore } from './modules/consumer-workflow/modules/consumer-submit-step/store/consumer-submit-step.store'
import { CoreRoute } from '../../libraries/router-library/components/core-route/core-route.component'
import { RouteGroup } from '../../libraries/router-library/components/route-group/route-group.component'
import { ConsumerRoute } from '../../domain/consumer/consumer-routes/consumer-route.enum'
import { ConsumerRouteSearchParam } from '../../domain/consumer/consumer-routes/consumer-route-search-param.enum'
import { ConsumerPostSubmissionApproved } from './modules/consumer-post-submission/modules/consumer-post-submission-approved/consumer-post-submission-approved.component'
import { ConsumerPostSubmissionDeclined } from './modules/consumer-post-submission/modules/consumer-post-submission-declined/consumer-post-submission-declined.component'
import { ConsumerPostSubmissionFailed } from './modules/consumer-post-submission/modules/consumer-post-submission-failed/consumer-post-submission-failed.component'
import { ConsumerPostSubmissionLoading } from './modules/consumer-post-submission/modules/consumer-post-submission-loading/consumer-post-submission-loading.component'
import { ConsumerPostSubmissionOffers } from './modules/consumer-post-submission/modules/consumer-post-submission-offers/consumer-post-submission-offers'
import { PreQualifierLoadingProduct } from './modules/pre-qualifier-loading-product/pre-qualifier-loading-product.component'
import { ConsumerProfileStore } from './modules/consumer-profile/store/consumer-profile.store'
import { clearSessionToken } from '../app/components/initializer/types/session-token.functions'
import { useHistory, useLocation } from 'react-router'
import {
  clearVendorAndProductIdFromLocalStorage,
  checkIfVendorAndProductIdExistInLocalStorage,
  getVendorIdFromLocalStorage,
  getProductIdFromLocalStorage
} from './modules/pre-qualifier-loading-product/types/pre-qualifier-product-local-storage.functions'
import { createRouteDueToResetRequest } from './types/consumer-routes.functions'
import { ConsumerProfilePostSubmissionConfigRulesVariant } from '../../domain/consumer/consumer-profile/types/consumer-profile-post-submission-config/types/consumer-profile-post-submission-config-rules-variant.enum'
import { GeoLocationStore } from './modules/geo-location/store/geo-location.store'
import { ProvinceAbbreviation } from '../../domain/province/province-abbreviation.enum'
import { addApprovedVariantToPath, addVendorIdToPath } from '../../shared/helpers/path-helper'
import { getPostSubmissionConfigApprovedOffersVariant } from './modules/consumer-post-submission/modules/consumer-post-submission-approved/types/consumer-post-submission-config.function'
import { getHighCostLicenseDetailsForProvince } from '../../shared/components/high-cost-license-details/high-cost-license-details.functions'

export const ConsumerRoutes = withShowIf(() => {
  const location = useLocation()
  const reset = new URLSearchParams(location.search).get(ConsumerRouteSearchParam.Reset)
  const history = useHistory()

  if (reset === 'true') {
    clearSessionToken();
    history.push(createRouteDueToResetRequest(location));
  }

  const workflowSteps = useSelector(ConsumerWorkflowStepsStore.getWorkflowSteps)
  const isSubmitWorkflowStepComplete = useSelector(
    ConsumerSubmitStepStore.getIsWorkflowStepComplete
  )
  const consumerSessionStatus = useConsumerSessionStatus()
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)
  
  const consumerAddress = useSelector(ConsumerProfileStore.getConsumerAddress)
  const consumerCoordinates = useSelector(GeoLocationStore.getCoordinates)
  const consumerProvince = useSelector(GeoLocationStore.getProvince)

  const postSubmissionConfig = useSelector(ConsumerProfileStore.getPostSubmissionConfig)
  const postSubmissionApprovalVariant = getPostSubmissionConfigApprovedOffersVariant(postSubmissionConfig)

  const isConsumerInPreSubmission = useMemo(
    () => consumerSessionStatus === ConsumerSessionStatus.PreSubmission,
    [consumerSessionStatus]
  )

  const isConsumerInPreSubmissionOrNotStarted = useMemo(
    () =>
      isConsumerInPreSubmission ||
      consumerSessionStatus === ConsumerSessionStatus.NotStarted,
    [consumerSessionStatus]
  )

  const isConsumerInPostSubmission = useMemo(
    () =>
      isSubmitWorkflowStepComplete ||
      consumerSessionStatus === ConsumerSessionStatus.PostSubmission,
    [isSubmitWorkflowStepComplete, consumerSessionStatus]
  )

  const consumerWorkflowRoutes = useMemo(
    () =>
      workflowSteps && isConsumerInPreSubmissionOrNotStarted
        ? createConsumerWorkflowRoutes(workflowSteps, vendorId)
        : null,
    [workflowSteps, consumerSessionStatus, vendorId]
  )

  useEffect(() => {
    if (location.pathname === ConsumerRoute.RouteResolver) {
      clearVendorAndProductIdFromLocalStorage()
    }

    if (getVendorIdFromLocalStorage()) {
      ConsumerProfileStore.setVendorId(getVendorIdFromLocalStorage())
    }
  }, [])

  useEffect(() => {
    if (
      consumerSessionStatus === ConsumerSessionStatus.NotStarted &&
      checkIfVendorAndProductIdExistInLocalStorage()
    ) {
      reRoute(
        getVendorIdFromLocalStorage(),
        getProductIdFromLocalStorage(),
        ConsumerRoute.LoadingProduct
      )
    }
  }, [consumerSessionStatus])

  useEffect(() => {
    if (isConsumerInPreSubmission && workflowSteps) {
      ConsumerWorkflowStepsStore.setActiveWorkflowStep(
        findActiveWorkflowStep(workflowSteps)
      )
    }
  }, [isConsumerInPreSubmission, workflowSteps])

  useEffect(() => {
    if (Boolean(navigator.geolocation)) {
      navigator.geolocation.getCurrentPosition(position => {
        GeoLocationStore.setConsumerCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      });
    }
  }, [])

  useEffect(() => {
    if (!Boolean(consumerAddress?.province) && consumerCoordinates !== null) {
      GeoLocationStore.fetchProvince(consumerCoordinates);
    }
  }, [consumerCoordinates])
  
  useEffect(() => {
    const province = (consumerAddress?.province as ProvinceAbbreviation) || consumerProvince?.abbreviation;
    const isApprovedRoute = history.location.pathname === addApprovedVariantToPath(
      addVendorIdToPath(vendorId, ConsumerRoute.SubmitApproved),
      postSubmissionApprovalVariant
    )

    if (Boolean(province) && (isConsumerInPreSubmissionOrNotStarted || isApprovedRoute)) {
      GeoLocationStore.setHighCostLicenseDetails(getHighCostLicenseDetailsForProvince(province))
    } else {
      GeoLocationStore.setHighCostLicenseDetails(null)
    }
  }, [consumerAddress, consumerProvince, isConsumerInPreSubmissionOrNotStarted, history.location.pathname]);

  const reRoute = (
    vendorId: string,
    productId: string,
    route: ConsumerRoute
  ) => {
    history.push(
      route.replace(':vendorId', vendorId).replace(':sku', productId)
    )
  }

  return (
    <RouteGroup>
      <CoreRoute
        key="pre-qualifier-asset-type-selection"
        path={ConsumerRoute.SelectAssetType}
        disableIntercom={true}
      >
        <PreQualifierAssetTypeSelection />
      </CoreRoute>
      <CoreRoute
        key="pre-qualifier-loading-product"
        path={ConsumerRoute.LoadingProduct}
        disableIntercom={true}
      >
        <PreQualifierLoadingProduct />
      </CoreRoute>
      {consumerWorkflowRoutes}
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="consumer-submit-loading"
        path={ConsumerRoute.SubmitLoading}
        exact
        disableIntercom={true}
      >
        <ConsumerPostSubmissionLoading />
      </DynamicRoute>
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="consumer-submit-approved"
        path={ConsumerRoute.SubmitApproved.replace(':variant', ConsumerProfilePostSubmissionConfigRulesVariant.Default)}
        exact
      >
        <ConsumerPostSubmissionApproved />
      </DynamicRoute>
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="consumer-submit-approved"
        path={ConsumerRoute.SubmitApproved.replace(':variant', ConsumerProfilePostSubmissionConfigRulesVariant.Offer)}
        exact
      >
        <>Approved Page | Variant: Offer</>
      </DynamicRoute>
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="consumer-submit-no-offer-available"
        path={ConsumerRoute.SubmitNoOfferAvailable}
        exact
        disableIntercom={true}
      >
        <ConsumerPostSubmissionDeclined />
      </DynamicRoute>
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="consumer-submit-error"
        path={ConsumerRoute.SubmitError}
        exact
        disableIntercom={true}
      >
        <ConsumerPostSubmissionFailed />
      </DynamicRoute>
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="consumer-submit-offers"
        path={ConsumerRoute.SubmitOffer}
        exact
        disableIntercom={true}
      >
        <ConsumerPostSubmissionOffers />
      </DynamicRoute>
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="merchant-selection-success"
        path={ConsumerRoute.SelectMerchantSuccess}
        exact
      >
        <MerchantSelectSuccess />
      </DynamicRoute>
      <DynamicRoute
        enabled={isConsumerInPostSubmission}
        redirectPath={ConsumerRoute.RouteResolver}
        key="merchant-selection"
        path={ConsumerRoute.SelectMerchant}
        exact
      >
        <MerchantSelection />
      </DynamicRoute>
      <CoreRoute
        key="consumer-authentication"
        path={ConsumerRoute.Authenticate}
        disableIntercom={true}
      >
        <ConsumerAuthentication />
      </CoreRoute>
      <CoreRoute path={ConsumerRoute.GenericVendorPage} exact disableIntercom={true}>
        <ConsumerRouteResolver />
      </CoreRoute>
      <CoreRoute>
        <ConsumerRouteResolver />
      </CoreRoute>
    </RouteGroup>
  )
})
